import React, { useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import Layout from 'components/_layout';
import { IUserDTO } from 'utils/dto';
import { Holder } from '../styles/pages/ordem-enviada';

const NotFound = (props: PageProps) => {
  const { location } = props;

  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);

  useEffect(() => {
    const userData: string | null = localStorage.getItem('@FGLivraria:user');
    if (!userData) {
      navigate('/minha-conta?redirect=123');
    } else {
      const data: IUserDTO = JSON.parse(userData);
      setUserEmail(data.email);
    }
  }, []);

  return (
    <Layout pathname={location.pathname}>
      <Holder>
        <h1>Seu pedido foi enviado com sucesso!</h1>
        <h2>
          Por conta de problemas com o pagamento direto pelo site, enviaremos um
          link para pagamento e mais informações via seu e-mail cadastrado (
          {userEmail}) ou via whatsapp caso possua. Por conta de segurança esse
          e-mail será enviado por{' '}
          <a href="mailto:contato@fglivraria.com">contato@fglivraria.com</a> ou
          via{' '}
          <a href="https://api.whatsapp.com/send?phone=5541991633300&text=Ol%C3%A1%2C%20FG%20Livraria!%20">
            (41) 99163-3300
          </a>{' '}
          e o link de pagamento será em um ambiente seguro.
          <br />
          <br />
          Caso tenha alguma dúvida, por favor, entre em contato no botão abaixo.
        </h2>

        <div>
          <a className="button" href="/contato">
            Entre em contato
          </a>
          <a className="button" href="/">
            Voltar para a página Inicial
          </a>
        </div>
      </Holder>
    </Layout>
  );
};

export default NotFound;
