import styled from 'styled-components';

export const Holder = styled.div`
  width: 100%;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    width: 90%;
    font-size: 48px;
    font-weight: 600;
    color: var(--main-bg);

    text-align: center;
  }
  h2 {
    width: 90%;
    font-size: 20px;
    font-weight: 400;
    color: #000;

    text-align: center;

    padding: 15px 0;
    line-height: 1.2;
  }
  div {
    margin-top: 30px;

    display: flex;
    a.button {
      border-radius: 30px;
      padding: 10px 30px;
      background-color: var(--main-bg);
      color: var(--main-color);
      font-weight: 600;
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

      text-align: center;
      margin: 0 15px;
    }
  }

  @media (max-width: 768px) {
    & {
      margin: 50px 0;
      h1 {
        font-size: 34px;
      }
      h2 {
        font-size: 18px;
      }
      h1,
      h2 {
        width: 90%;
      }
      div {
        flex-direction: column;
        a.button {
          margin: 15px 0;
        }
      }
    }
  }
`;
